import axios from 'axios';
import { useState ,useEffect} from 'react';
import dayjs, { Dayjs } from 'dayjs';
import jwtDecode from "jwt-decode";
import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography,FormControl,MenuItem ,Select,InputLabel,Button } from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// components
import Iconify from '../components/iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';

// api
import api from '../config/services';
// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();

  const userData = JSON.parse(localStorage.getItem('userInfo'));
  const webIdStorage = localStorage.getItem('webId');
  const [webId, setWebId] = useState(webIdStorage !== null ? webIdStorage : 1);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [dataWebList,setDataWebList] = useState([]);
  const [slot,setSlot] = useState(0);
  const [sport,setSport] = useState(0);
  const [casino,setCasino] = useState(0);

  useEffect(() => {
    getWebList();
    getTotalWinLoss();
  }, []);

  const getWebList = async() => {
    const url = `${api.getWebList}`;
    await axios
      .get(url,{
        'headers': {
          'Authorization': `Bearer ${userData.token}`
        }
      })
      .then((res) => {
        const { data } = res;
        if (data.type === 'success') {
           setDataWebList(data.data);
        }
      })
      .catch((err) => {
        // console.log(err);
        if(err.response.data.error.status === 401){
          localStorage.clear();
          window.location.reload();
        }
      });
  };

  const getTotalWinLoss = async() => {
    const date = `${selectedDate.$y}-${selectedDate.$M + 1}-${selectedDate.$D}`
    const url = `${api.getWebWinLoss}/${webId}/${date}`;
    await axios
      .get(url,{
        headers: {
          'Authorization': `Bearer ${userData.token}`
        }
      })
      .then((res) => {
        const { data } = res;
        if (data.type === 'success') {
          const total = data.data.data;
          setSlot(total[0] ? total[0].total_play * -1 : 0);
          setSport(total[1] ? total[1].total_play * -1 : 0);
          setCasino(total[2] ? total[2].total_play * -1 : 0);
        }
      })
      .catch((err) => {
        // console.log(err);
        if(err.response.data.error.status === 401){
          localStorage.clear();
          window.location.reload();
        }
      });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleChange = (event) => {
    setWebId(event.target.value);
    localStorage.setItem('webId', event.target.value);
  };


  return (
    <>
      <Helmet>
        <title> Dashboard | Minimal UI </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Win-loss Report
        </Typography>
        <Grid container spacing={4} sx={{mb: 5,justifyContent:'flex-end',alignItems:'flex-end'}}>
          <LocalizationProvider dateAdapter={AdapterDayjs} >
            <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']} sx={{marginRight:'0.5rem'}} >
              <DatePicker 
              label={'Month and year'} 
              views={['month', 'year']} 
              value={selectedDate}
              onChange={(newValue) => handleDateChange(newValue)}
              
              />
            </DemoContainer>
          </LocalizationProvider>

          <FormControl sx={{width:"40%",marginRight:"0.5rem"}}>
            <InputLabel id="demo-simple-select-label">Web</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={webId}
              label="Age"
              onChange={handleChange}
            >
              {
                dataWebList.map((element,key) => (
                  <MenuItem key={key} value={element.id}>{element.name}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
          <Button variant="contained" sx={{padding:'16px 16px'}} onClick={getTotalWinLoss}> Search </Button>
        </Grid>
        

        <Grid container spacing={4} sx={{ mb: 5 }}>
          <Grid item xs={12} sm={6} md={12}>
            <AppWidgetSummary title="Total" total={slot+sport+casino} color="success" icon={'nimbus:money'} />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary title="Slot" total={slot} color='secondary' icon={'mdi:slot-machine-outline'} />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary title="Sport" total={sport} color="info" icon={'fluent:sport-soccer-20-regular'} />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary title="Casino" total={casino} color="warning" icon={'game-icons:poker-hand'} />
          </Grid>

        </Grid>
      </Container>
    </>
  );
}
