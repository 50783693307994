import { checkRoleAdminOnly } from '../../../utils/checkRole';
// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;
const userData = JSON.parse(localStorage.getItem('userInfo'));
const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
    display: 'flex',
    auth: ['Administrator', 'Developer', 'Support', 'Customer'],
  },
  {
    title: 'dashboard SMS',
    path: '/dashboard/sms-last',
    icon: icon('ic_analytics'),
    display: 'flex',
    auth: ['Administrator', 'Developer', 'SMS'],
  },
  {
    title: 'พนักงาน',
    path: '/dashboard/user',
    icon: icon('ic_user'),
    display: userData ? (checkRoleAdminOnly(userData.token) ? 'flex' : 'none') : 'none',
    auth: ['Administrator', 'Developer'],
  },
  {
    title: 'เบอร์เว็บ',
    path: '/dashboard/web-phone',
    icon: icon('ic_user'),
    display: userData ? (checkRoleAdminOnly(userData.token) ? 'flex' : 'none') : 'none',
    auth: ['Administrator', 'Developer'],
  },
  {
    title: 'SMS',
    path: '/dashboard/sms',
    icon: icon('ic_user'),
    display: userData ? (checkRoleAdminOnly(userData.token) ? 'flex' : 'none') : 'none',
    auth: ['Administrator', 'Developer', 'SMS'],
  },
];

export default navConfig;
