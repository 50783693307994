import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import Swal from 'sweetalert2';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Modal,
  TextField,
  Box,
  Alert,
  AlertTitle,
  Snackbar,
  Switch,
} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// api
import api from '../config/services';
import { fDateTime } from '../utils/formatTime';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'none' },
  { id: 'name', label: 'ชื่อ', alignRight: false },
  { id: 'email', label: 'อีเมล', alignRight: false },
  { id: 'role', label: 'ตำแหน่ง', alignRight: false },
  { id: 'status', label: 'สถานะ', alignRight: false },
  { id: 'recent login', label: 'วันที่ล็อกอินล่าสุด', alignRight: false },
  { id: 'phone_action', label: 'เบอร์มือถือ', align: 'center' },
  { id: 'action', label: 'การจัดการ', alignRight: false },
];

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserPage() {
  const navigate = useNavigate();

  const userData = JSON.parse(localStorage.getItem('userInfo'));
  const [dataUserList, setDataUserList] = useState([]);

  useEffect(() => {
    getUserList();
    getRoleList();
    // getSmsWebList();
  }, []);

  // get data
  const getUserList = async () => {
    const url = `${api.getUserList}`;
    await axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      })
      .then((res) => {
        const { data } = res;
        if (data.type === 'success') {
          setDataUserList(data.data);
        }
      })
      .catch((err) => {
        if (err.response.data.error.status === 401) {
          Swal.fire({
            title: 'Error!',
            text: err.response.data.error.errorType,
            icon: 'error',
            confirmButtonText: 'ตกลง',
          }).then((action) => {
            localStorage.clear();
            window.location.reload();
          });
        }
      });
  };
  const getRoleList = async () => {
    const url = `${api.getRoleList}`;
    await axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      })
      .then((res) => {
        const { data } = res;
        if (data.type === 'success') {
          setRoleList(data.data);
        }
      })
      .catch((err) => {
        if (err.response.data.error.status === 401) {
          Swal.fire({
            title: 'Error!',
            text: err.response.data.error.errorType,
            icon: 'error',
            confirmButtonText: 'ตกลง',
          }).then((action) => {
            localStorage.clear();
            window.location.reload();
          });
        }
      });
  };
  const getSmsWebList = async () => {
    const url = `${api.getSmsWebList}`;
    await axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      })
      .then((res) => {
        const { data } = res;
        if (data.type === 'success') {
          setWebList(data.data);
        }
      })
      .catch((err) => {
        if (err.response.data.error.status === 401) {
          Swal.fire({
            title: 'Error!',
            text: err.response.data.error.errorType,
            icon: 'error',
            confirmButtonText: 'ตกลง',
          }).then((action) => {
            localStorage.clear();
            window.location.reload();
          });
        }
      });
  };
  // get data

  // create user
  const [roleList, setRoleList] = useState([]);
  const [webList, setWebList] = useState([]);
  const [selectRole, setSelectRole] = useState('');
  const [selectWeb, setSelectWeb] = useState('');
  const [inputUserData, setInputUserData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    roleId: '',
  });
  const [errors, setErrors] = useState({});
  const [showPopup, setShowPopup] = useState(false);

  const handleModalAddUser = () => {
    setInputUserData({});
    setSelectRole('');
    setErrors({});
    setShowPopup(!showPopup);
  };

  const submitCreateUser = () => {
    if (validateCreateUser()) {
      createAdminUser();
    }
  };

  const submitCreateUserPhone = () => {
    if (validateCreateUserPhone()) {
      createUserPhone();
    }
  };

  const inputEvent = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputUserData((lastValue) => {
      return {
        ...lastValue,
        [name]: value,
      };
    });

    if (errors !== '') {
      setErrors({});
    }
  };

  const handleSelectRole = (event) => {
    setSelectRole(event.target.value);
  };

  const handleSelectWeb = (event) => {
    setSelectWeb(event.target.value);
  };

  function validateCreateUser() {
    const input = inputUserData;
    const errors = {};
    let isValid = true;

    if (!input.userName) {
      isValid = false;
      errors.userName = 'โปรดใส่ชื่อผู้ใช้งาน';
    }

    if (!input.firstName) {
      isValid = false;
      errors.firstName = 'โปรดใส่ชื่อ';
    }

    if (!input.lastName) {
      isValid = false;
      errors.lastName = 'โปรดใส่นามสกุล';
    }

    if (!input.email) {
      isValid = false;
      errors.email = 'โปรดใส่อีเมล';
    }

    if (!input.password) {
      isValid = false;
      errors.password = 'โปรดใส่รหัสผ่าน';
    }

    if (!selectRole) {
      isValid = false;
      errors.role = 'โปรดเลือกตำแหน่ง';
    }

    setErrors(errors);

    return isValid;
  }

  function validateCreateUserPhone() {
    const input = userPhoneData;
    const errors = {};
    let isValid = true;

    if (!input.phone) {
      isValid = false;
      errors.phone = 'โปรดใส่เบอร์มือถือ';
    }

    setErrors(errors);

    return isValid;
  }

  const createAdminUser = async () => {
    const url = api.createUser;
    const data = inputUserData;
    const phone = data.phone;
    data.roleId = selectRole.toString();

    await axios
      .post(url, data, {
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      })
      .then((res) => {
        const { data } = res;
        if (data.type === 'success') {
          getUserList();
          handleModalAddUser();
          Swal.fire({
            title: 'Success',
            text: 'สร้างผู้ใช้งานสำเร็จ',
            icon: 'success',
            confirmButtonText: 'ตกลง',
          });
        } else {
          Swal.fire({
            title: 'Error!',
            text: 'พบข้อผิดพลาด เพิ่มผู้ใช้งานไม่สำเร็จ',
            icon: 'error',
            confirmButtonText: 'ตกลง',
          });
          errors.result = 'พบข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ';
          setErrors(errors);
        }
      })
      .catch((err) => {
        Swal.fire({
          title: 'Error!',
          text: 'พบข้อผิดพลาด เพิ่มผู้ใช้งานไม่สำเร็จ',
          icon: 'error',
          confirmButtonText: 'ตกลง',
        });
      });
  };

  const createUserPhone = async () => {
    const url = api.createUserPhone;

    await axios
      .post(url, userPhoneData, {
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      })
      .then((res) => {
        const { data } = res;
        if (data.type === 'success') {
          Swal.fire({
            title: 'Success',
            text: 'เพิ่มเบอร์มือถือผู้ใช้งานสำเร็จ',
            icon: 'success',
            confirmButtonText: 'ตกลง',
          });
        } else {
          Swal.fire({
            title: 'Error!',
            text: 'เพิ่มเบอร์มือถือผู้ใช้งานสำเร็จ',
            icon: 'error',
            confirmButtonText: 'ตกลง',
          });
        }

        getUserList();
        handleCloseAddPhone();
      })
      .catch((err) => {
        if (err.response.data.error.status === 400) {
          Swal.fire({
            title: 'Error!',
            text: 'มีเบอร์มือถือในผู้ใช้งานนี้แล้ว โปรดกรอกเบอร์มือถือใหม่',
            icon: 'error',
            confirmButtonText: 'ตกลง',
          });
        } else {
          Swal.fire({
            title: 'Error!',
            text: 'เพิ่มผู้เบอร์มือถือผู้ใช้งานไม่สำเร็จ',
            icon: 'error',
            confirmButtonText: 'ตกลง',
          });
        }
      });
  };
  // create user

  // delete user
  const handleDeletedUser = () => {
    handleCloseMenu();
    Swal.fire({
      title: `คุณต้องการลบ หรือไม่?`,
      text: 'หากลบแล้วจะไม่สามารถใช้งานได้อีก',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
    }).then(async (result) => {
      if (result.isConfirmed) {
        deleteUser();
      }
    });
  };

  const deleteUser = async () => {
    const url = `${api.deleteUser}/${deleteId}`;

    await axios
      .delete(url, {
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      })
      .then((res) => {
        const { data } = res;
        if (data.type === 'success') {
          setDeleteId('');
          getUserList();
          Swal.fire({
            title: 'Success',
            text: 'ลบผู้ใช้งานสำเร็จ',
            icon: 'success',
            confirmButtonText: 'ตกลง',
          });
        } else {
          Swal.fire({
            title: 'Error!',
            text: 'พบข้อผิดพลาด เพิ่มผู้ใช้งานไม่สำเร็จ',
            icon: 'error',
            confirmButtonText: 'ตกลง',
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          title: 'Error!',
          text: 'พบข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ',
          icon: 'error',
          confirmButtonText: 'ตกลง',
        });
      });
  };
  // delete user

  const handleDeletedUserPhone = (data) => {
    Swal.fire({
      title: `คุณต้องการลบเบอร์ ${data.phone} หรือไม่?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
    }).then(async (result) => {
      if (result.isConfirmed) {
        deleteUserPhone(data.id);
      }
    });
  };

  const deleteUserPhone = async (id) => {
    const url = `${api.deleteUserPhone}/${id}`;

    await axios
      .delete(url, {
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      })
      .then((res) => {
        const { data } = res;
        if (data.type === 'success') {
          getUserList();
          handleCloseUserPhone();
          Swal.fire({
            title: 'Success',
            text: 'ลบเบอร์มือถือสำเร็จ',
            icon: 'success',
            confirmButtonText: 'ตกลง',
          });
        } else {
          Swal.fire({
            title: 'Error!',
            text: 'พบข้อผิดพลาด ลบเบอร์มือถือไม่สำเร็จ',
            icon: 'error',
            confirmButtonText: 'ตกลง',
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          title: 'Error!',
          text: 'พบข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ',
          icon: 'error',
          confirmButtonText: 'ตกลง',
        });
      });
  };

  // update status

  const handleChangeStatus = (isStatus, id) => {
    updateStatus(isStatus, id);
  };

  const handleChangeReceiver = (isStatus, id) => {
    // updateStatus(isStatus, id);
  };

  const updateStatus = async (isStatus, id) => {
    const url = `${api.statusUser}/${id}`;
    const status = !isStatus;
    await axios
      .put(
        url,
        { isStatus: `${status.toString()}` },
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        }
      )
      .then((res) => {
        const { data } = res;
        if (data.type === 'success') {
          getUserList();
          Swal.fire({
            title: 'Success',
            text: 'ปรับสถานะผู้ใช้สำเร็จ',
            icon: 'success',
            confirmButtonText: 'ตกลง',
          });
        } else {
          Swal.fire({
            title: 'Error!',
            text: 'พบข้อผิดพลาด ปรับสถานะผู้ใช้ไม่สำเร็จ',
            icon: 'error',
            confirmButtonText: 'ตกลง',
          });
          errors.result = 'พบข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ';
          setErrors(errors);
        }
      })
      .catch((err) => {
        Swal.fire({
          title: 'Error!',
          text: 'พบข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ',
          icon: 'error',
          confirmButtonText: 'ตกลง',
        });
      });
  };

  // update status

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [deleteId, setDeleteId] = useState('');
  const handleOpenMenu = (event, id) => {
    setDeleteId(id);
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setDeleteId('');
    setOpen(null);
  };

  const [userPhoneData, setUserPhoneData] = useState({ phone: '', userId: null });
  const [openUserPhoneData, setOpenUserPhoneData] = useState(false);
  const handleOpenAddPhone = (userId) => {
    const data = { phone: '', userId: userId.toString() };
    setUserPhoneData(data);
    setOpenUserPhoneData(true);
  };

  const inputEventAddPhone = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setUserPhoneData((lastValue) => {
      return {
        ...lastValue,
        [name]: value,
      };
    });

    if (errors !== '') {
      setErrors({});
    }
  };

  const handleCloseAddPhone = () => {
    const defaultData = { phone: '', userId: null };
    setUserPhoneData(defaultData);
    setOpenUserPhoneData(false);
  };

  const [displayPhoneData, setDisplayPhoneData] = useState(null);
  const [openPhoneData, setOpenPhoneData] = useState(false);
  const handleOpenUserPhone = (data) => {
    setDisplayPhoneData(data);
    setOpenPhoneData(true);
  };

  const handleCloseUserPhone = () => {
    setDisplayPhoneData(null);
    setOpenPhoneData(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataUserList.length) : 0;

  const renderUserPhoneList = () => {
    let resData = [];
    for (const item of displayPhoneData.phonesList) {
      resData.push(
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0.5rem 0',
          }}
          key={`phone_${item.phone}`}
        >
          <Typography gutterBottom sx={{ p: 0, m: 0 }}>
            {item.phone}
          </Typography>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleDeletedUserPhone(item)}
            startIcon={<Iconify icon="eva:trash-2-outline" />}
          >
            ลบเบอร์
          </Button>
        </Box>
      );
    }
    return resData;
  };
  return (
    <>
      <Helmet>
        <title> ระบบจัดการพนักงาน | AK service </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            ระบบจัดการพนักงาน
          </Typography>
          <Button variant="contained" onClick={() => handleModalAddUser()} startIcon={<Iconify icon="eva:plus-fill" />}>
            สร้างบัญชี
          </Button>
        </Stack>

        {/* Modal add user  */}
        <Modal
          open={showPopup}
          onClose={handleModalAddUser}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box sx={{ ...style, width: 400, height: '60vh', overflowY: 'auto' }}>
            <h2 id="parent-modal-title">{'เพิ่มบัญชีพนักงาน'}</h2>
            <p id="parent-modal-description">กรุณากรอกข้อมูลให้ครบถ้วน</p>

            <span className="error-text">{errors.result}</span>

            <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
              <TextField
                id="userName"
                label="ชื่อผู้ใช้งาน"
                name="userName"
                value={inputUserData.userName}
                onChange={inputEvent}
                variant="outlined"
                error={Boolean(errors.userName)}
                helperText={errors.userName}
              />
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
              <TextField
                id="firstName"
                label="ชื่อ"
                name="firstName"
                value={inputUserData.firstName}
                onChange={inputEvent}
                variant="outlined"
                error={Boolean(errors.firstName)}
                helperText={errors.firstName}
              />
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
              <TextField
                id="lastName"
                label="นามสกุล"
                name="lastName"
                value={inputUserData.lastName}
                onChange={inputEvent}
                variant="outlined"
                error={Boolean(errors.lastName)}
                helperText={errors.lastName}
              />
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
              <TextField
                id="email"
                label="อีเมล"
                name="email"
                value={inputUserData.email}
                onChange={inputEvent}
                variant="outlined"
                error={Boolean(errors.email)}
                helperText={errors.email}
              />
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
              <TextField
                id="password"
                label="รหัสผ่าน"
                name="password"
                type="password"
                value={inputUserData.password}
                onChange={inputEvent}
                variant="outlined"
                error={Boolean(errors.password)}
                helperText={errors.password}
              />
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: '16px' }}>
              <TextField
                select
                id="roleId"
                value={selectRole}
                label="ตำแหน่ง"
                placeholder="เลือกตำแหน่ง"
                InputLabelProps={{ shrink: true }}
                sx={{
                  marginTop: '10px',
                  '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'red',
                  },
                }}
                onChange={handleSelectRole}
                variant="outlined"
                error={Boolean(errors.role)}
                helperText={errors.role}
              >
                {roleList.map((role) => (
                  <MenuItem key={role.id} value={role.id}>
                    {role.name}
                  </MenuItem>
                ))}
              </TextField>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '50px' }}>
              <Button onClick={() => submitCreateUser()} variant="contained">
                บันทึก
              </Button>
            </Box>
          </Box>
        </Modal>

        {/* Modal add user phone */}
        <Modal
          open={openUserPhoneData}
          onClose={handleCloseAddPhone}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box sx={{ ...style, width: 400, height: '60vh', overflowY: 'auto' }}>
            <h2 id="parent-modal-title">{'เพิ่มเบอร์มือถือ'}</h2>
            <p id="parent-modal-description">เพื่อใช้ในการดู sms (สามารถเพิ่มหลายเบอร์ได้)</p>

            <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
              <TextField
                id="phone"
                label="เบอร์มือถือ"
                name="phone"
                value={userPhoneData.phone}
                onChange={inputEventAddPhone}
                variant="outlined"
                error={Boolean(errors.phone)}
                helperText={errors.phone}
              />
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '50px' }}>
              <Button onClick={() => submitCreateUserPhone()} variant="contained">
                บันทึก
              </Button>
            </Box>
          </Box>
        </Modal>

        {/* Modal show user phone */}
        <Modal
          open={openPhoneData}
          onClose={handleCloseUserPhone}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box sx={{ ...style, width: 400, height: '60vh', overflowY: 'auto' }}>
            <h2 id="parent-modal-title" style={{ marginBottom: 0 }}>
              เบอร์มือถือพนักงาน
            </h2>
            <Typography variant="h5" id="parent-modal-title" sx={{ m: 0, mb: 2 }}>
              {displayPhoneData && `${displayPhoneData.firstName} ${displayPhoneData.lastName}`}
            </Typography>

            {displayPhoneData && displayPhoneData.phonesList.length > 0
              ? renderUserPhoneList()
              : 'ไม่มีเบอร์มือถือที่ผูก'}

            <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '50px' }}>
              <Button onClick={() => handleCloseUserPhone()} variant="contained">
                กลับ
              </Button>
            </Box>
          </Box>
        </Modal>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={dataUserList.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {dataUserList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, firstName, lastName, role, isStatus, email, phone, receiver, recentLogin } = row;
                    return (
                      <TableRow key={id}>
                        <TableCell align="left"> </TableCell>
                        <TableCell component="th" scope="row">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="subtitle2" noWrap>
                              {firstName} {lastName}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell align="left">{email}</TableCell>
                        <TableCell align="left">{role.name}</TableCell>
                        {/* <TableCell align="left">{phone}</TableCell> */}

                        <TableCell sx={{ color: isStatus ? 'green' : 'red', padding: '0 1rem 0 0' }} align="left">
                          <FormControlLabel
                            control={<IOSSwitch sx={{ padding: 0 }} />}
                            label={isStatus ? 'เปิด' : 'ปิด'}
                            labelPlacement="top"
                            checked={isStatus}
                            onChange={() => handleChangeStatus(isStatus, id)}
                          />
                        </TableCell>

                        <TableCell align="left">{fDateTime(recentLogin)}</TableCell>

                        <TableCell align="center">
                          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '0.5rem', justifyContent: 'center' }}>
                            <Button
                              style={{ width: '120px' }}
                              onClick={() => handleOpenUserPhone(row)}
                              color="primary"
                              variant="outlined"
                            >
                              ดูเบอร์
                            </Button>
                            <Button
                              style={{ width: '120px' }}
                              onClick={() => handleOpenAddPhone(id)}
                              color="warning"
                              variant="contained"
                            >
                              เพิ่มเบอร์
                            </Button>
                          </Box>
                        </TableCell>

                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, id)}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 15, 20, 30, 50]}
            component="div"
            count={dataUserList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        {/* <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          แก้ไข
        </MenuItem> */}
        <MenuItem sx={{ color: 'error.main' }} onClick={handleDeletedUser}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          ลบ
        </MenuItem>
      </Popover>
    </>
  );
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  borderRadius: '1rem',
  borderColor: 'transparent',
};
