import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Navigate, useRoutes, useNavigate, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import jwtDecode from 'jwt-decode';
// api
import api from './config/services';

import { isTokenExpired } from './utils';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import SMSPage from './pages/SMSPage';
import WebPhonePage from './pages/WebPhonePage'
import DashboardSMSPage from './pages/DashboardSMSPage'

import './style/index.css';

// ----------------------------------------------------------------------

export default function Router() {
  const location = useLocation();
  const navigate = useNavigate();

  function RequireAuth({ children }: { children: JSX.Element }) {
    const accessRolesList = children.props ? children.props.roles : [];
    const accessToken = localStorage.getItem('accessToken') || null;

    const decoded = accessToken ? jwtDecode(accessToken) : null;
    const userexp = decoded ? decoded.payload.exp : null;
    const userid = decoded ? decoded.payload.id : null;
    const userRole = decoded ? decoded.payload.role.name : null;

    const loginPathName = ['/', '/login'];
    const isLoginPage = loginPathName.includes(location.pathname);

    // useEffect(() => {
    //   if (isLoginPage) {
    //   } else {
    //     if (decoded && isTokenExpired(userexp)) {
    //       removeToken(401, 'expired');
    //     } else if (decoded && !isTokenExpired(userexp)) {
    //       updateRecentLogin(userid, accessToken);
    //     }
    //   }
    // }, []);

    if (accessToken) {
      if (accessRolesList) {
        const canAccess = accessRolesList.includes('*') || accessRolesList.includes(userRole);
        if (isLoginPage) {
          if (userRole === 'SMS') {
            return <Navigate to="/dashboard/sms-last" state={{ from: location }} replace />;
          } else {
            return <Navigate to="/dashboard/app" state={{ from: location }} replace />;
          }
        } else {
          if (canAccess) {
            if (userRole === 'SMS' && (isLoginPage || location.pathname === '/dashboard/app')) {
              window.location.href = '/dashboard/sms-last';
            } else {
              return children;
            }
          } else {
            return <Navigate to="/404" state={{ from: location }} replace />;
          }
        }
      } else {
        return <Navigate to="/404" state={{ from: location }} replace />;
      }
    } else if (accessToken === null && location.pathname !== '/login') {
      return <Navigate to="/login" state={{ from: location }} replace />;
    } else {
      return children;
    }
  }

  const updateRecentLogin = async (id, token) => {
    const url = `${api.updateRecentLogin}/${id}`;
    await axios
      .put(
        url,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        const { data } = res;
        if (data.type === 'success') {
          const resdata = data.data;
          localStorage.setItem('userInfo', JSON.stringify(resdata));
          localStorage.setItem('accessToken', resdata.token);
        } else {
          localStorage.clear();
          Swal.fire({
            title: 'Error!',
            text: 'ไม่สามารถอัพเดตได้ โปรดติดต่อผู้ดูแลระบบ',
            icon: 'error',
            confirmButtonText: 'ตกลง',
          }).then((action) => {
            window.location.reload();
          });
        }
      })
      .catch((err) => {
        localStorage.clear();
        if (err.response.data.error.status === 401) {
          Swal.fire({
            title: 'Error!',
            text: 'ไม่สามารถอัพเดตได้ โปรดติดต่อผู้ดูแลระบบ',
            icon: 'error',
            confirmButtonText: 'ตกลง',
          }).then((action) => {
            window.location.reload();
          });
        }
      });
  };

  const removeToken = (status_code, type) => {
    localStorage.clear();

    Swal.fire({
      icon: 'error',
      title: 'พบข้อผิดพลาด',
      text: status_code === 401 ? `ไม่พบ token หรือ token หมดอายุ โปรด login ใหม่อีกครั้ง` : `ไม่สามารถรับข้อมูลได้`,
      confirmButtonText: 'ยืนยัน',
    }).then((action) => {
      navigate('/login', { replace: true });
      // window.location.reload(false);
    });
  };

  const routes = useRoutes([
    {
      path: '/dashboard',
      element: (
        <RequireAuth>
          <DashboardLayout roles={['*']} />
        </RequireAuth>
      ),
      children: [
        {
          element: <Navigate to="/dashboard/app" roles={['Administrator', 'Developer', 'Support', 'Customer']} />,
          index: true,
        },
        { path: 'app', element: <DashboardAppPage roles={['Administrator', 'Developer', 'Support', 'Customer']} /> },
        { path: 'sms-last', element: <DashboardSMSPage roles={['Administrator', 'Developer', 'SMS']} /> },
        { path: 'user', element: <UserPage roles={['Administrator', 'Developer']} /> },
        { path: 'web-phone', element: <WebPhonePage roles={['Administrator', 'Developer']} /> },
        { path: 'sms', element: <SMSPage roles={['Administrator','SMS']} /> },
      ],
    },
    {
      path: '/',
      element: (
        <RequireAuth>
          <LoginPage roles={['*']} />
        </RequireAuth>
      ),
    },
    {
      path: 'login',
      element: (
        <RequireAuth>
          <LoginPage roles={['*']} />
        </RequireAuth>
      ),
    },

    {
      element: <SimpleLayout />,
      children: [
        // { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
