import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import Swal from 'sweetalert2';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Modal,
  TextField,
  Box,
  Alert,
  AlertTitle,
  Snackbar,
  Switch,
} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// api
import api from '../config/services';
import { fDateTime } from '../utils/formatTime';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'none' },
  { id: 'no', label: 'ลำดับ', alignRight: false },
  { id: 'name', label: 'ชื่อบัญชี', alignRight: false },
  { id: 'phone', label: 'เบอร์มือถือ', alignRight: false },
  { id: 'web', label: 'เว็บ', alignRight: false },
  { id: 'action', label: 'การจัดการ', align: 'center' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function WebPhonePage() {
  const userData = JSON.parse(localStorage.getItem('userInfo'));
  const [webList, setWebList] = useState([]);
  const [webPhoneList, setWebPhoneList] = useState([]);
  const [inputWebPhoneData, setInputWebPhoneData] = useState({
    webId: '',
    phone: '',
    name: '',
  });
  const [errors, setErrors] = useState({});
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    getWebPhoneList();
    getWebList();
  }, []);

  // get data
  const getWebPhoneList = async () => {
    const url = `${api.getSmsWebPhoneList}`;
    await axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      })
      .then((res) => {
        const { data } = res;
        if (data.type === 'success') {
          const resData = data.data;

          if (resData && resData.length > 0) {
            let webPhoneList = [];
            for (const resdata of resData) {
              const smsPhone = resdata.sms_phones;
              if (smsPhone && smsPhone.length > 0) {
                for (const smsphone of smsPhone) {
                  webPhoneList.push({ ...smsphone, web: resdata.name });
                }
              }
            }
            webPhoneList.sort((a, b) => a.id - b.id).reverse();

            setWebPhoneList(webPhoneList);
          } else {
            setWebPhoneList(data.data);
          }
        }
      })
      .catch((err) => {
        if (err.response.data.error.status === 401) {
          Swal.fire({
            title: 'Error!',
            text: err.response.data.error.errorType,
            icon: 'error',
            confirmButtonText: 'ตกลง',
          }).then((action) => {
            localStorage.clear();
            window.location.reload();
          });
        }
      });
  };
  const getWebList = async () => {
    const url = `${api.getSmsWebList}`;
    await axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      })
      .then((res) => {
        const { data } = res;
        if (data.type === 'success') {
          const resData = data.data;
          setWebList(resData);
        }
      })
      .catch((err) => {
        if (err.response.data.error.status === 401) {
          Swal.fire({
            title: 'Error!',
            text: err.response.data.error.errorType,
            icon: 'error',
            confirmButtonText: 'ตกลง',
          }).then((action) => {
            localStorage.clear();
            window.location.reload();
          });
        }
      });
  };
  // get data

  const submitCreateWebPhone = () => {
    if (validateCreateWebPhone()) {
      createWebPhone();
    }
  };

  const inputEvent = (event) => {
    const name = event.target.name;
    let value = event.target.value;

    if (name === 'webId') {
      value = value.toString();
    }
    setInputWebPhoneData((lastValue) => {
      return {
        ...lastValue,
        [name]: value,
      };
    });

    if (errors !== '') {
      setErrors({});
    }
  };

  function validateCreateWebPhone() {
    const input = inputWebPhoneData;
    const errors = {};
    let isValid = true;

    if (!input.name) {
      isValid = false;
      errors.name = 'โปรดใส่ชื่อบัญชี';
    }

    if (!input.phone) {
      isValid = false;
      errors.phone = 'โปรดใส่เบอร์มือถือ';
    }

    if (!input.webId) {
      isValid = false;
      errors.webId = 'โปรดเลือกเว็บ';
    }

    setErrors(errors);

    return isValid;
  }

  const createWebPhone = async () => {
    const url = api.createWebPhone;

    await axios
      .post(url, inputWebPhoneData, {
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      })
      .then((res) => {
        const { data } = res;
        if (data.type === 'success') {
          Swal.fire({
            title: 'Success',
            text: 'เพิ่มเบอร์มือถือผู้ใช้งานสำเร็จ',
            icon: 'success',
            confirmButtonText: 'ตกลง',
          });
        } else {
          Swal.fire({
            title: 'Error!',
            text: 'เพิ่มเบอร์มือถือผู้ใช้งานสำเร็จ',
            icon: 'error',
            confirmButtonText: 'ตกลง',
          });
        }

        getWebPhoneList();
        handleCloseAddPhone();
      })
      .catch((err) => {
        if (err.response.data.error.status === 400) {
          Swal.fire({
            title: 'Error!',
            text: 'มีเบอร์มือถือในผู้ใช้งานนี้แล้ว โปรดกรอกเบอร์มือถือใหม่',
            icon: 'error',
            confirmButtonText: 'ตกลง',
          });
        } else {
          Swal.fire({
            title: 'Error!',
            text: 'เพิ่มผู้เบอร์มือถือผู้ใช้งานไม่สำเร็จ',
            icon: 'error',
            confirmButtonText: 'ตกลง',
          });
        }
      });
  };

  const handleDeletedWebPhone = (data) => {
    Swal.fire({
      title: `คุณต้องการลบเบอร์หรือไม่?`,
      text: `คุณต้องการลบเบอร์ ${data.phone} ของ ${data.name} หรือไม่?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
    }).then(async (result) => {
      if (result.isConfirmed) {
        deleteWebPhone(data.id);
      }
    });
  };

  const deleteWebPhone = async (id) => {
    const url = `${api.deleteWebPhone}/${id}`;

    await axios
      .delete(url, {
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      })
      .then((res) => {
        const { data } = res;
        if (data.type === 'success') {
          getWebPhoneList();
          Swal.fire({
            title: 'Success',
            text: 'ลบเบอร์มือถือสำเร็จ',
            icon: 'success',
            confirmButtonText: 'ตกลง',
          });
        } else {
          Swal.fire({
            title: 'Error!',
            text: 'พบข้อผิดพลาด ลบเบอร์มือถือไม่สำเร็จ',
            icon: 'error',
            confirmButtonText: 'ตกลง',
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          title: 'Error!',
          text: 'พบข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ',
          icon: 'error',
          confirmButtonText: 'ตกลง',
        });
      });
  };

  // data table
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(null);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - webPhoneList.length) : 0;
  // data table

  // modal
  const [openinputWebPhoneData, setOpeninputWebPhoneData] = useState(false);
  const handleOpenAddPhone = () => {
    setOpeninputWebPhoneData(true);
  };

  const handleCloseAddPhone = () => {
    const defaultData = { webId: '', phone: '', name: '' };
    setInputWebPhoneData(defaultData);
    setOpeninputWebPhoneData(false);
  };
  // modal
  return (
    <>
      <Helmet>
        <title> ระบบจัดการเบอร์เว็บ | AK Service </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            ระบบจัดการเบอร์เว็บ
          </Typography>
          <Button variant="contained" onClick={() => handleOpenAddPhone()} startIcon={<Iconify icon="eva:plus-fill" />}>
            สร้างบัญชี
          </Button>
        </Stack>

        {/* Modal add user phone */}
        <Modal
          open={openinputWebPhoneData}
          onClose={handleCloseAddPhone}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box sx={{ ...style, width: 400, height: '60vh', overflowY: 'auto' }}>
            <h2 id="parent-modal-title">{'เพิ่มบัญชีและเบอร์เว็บ'}</h2>
            <p id="parent-modal-description">กรุณากรอกข้อมูลให้ครบถ้วน</p>

            <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
              <TextField
                id="name"
                label="ชื่อบัญชี"
                name="name"
                placeholder="กรอกชื่อและนามสกุล"
                value={inputWebPhoneData.name}
                onChange={inputEvent}
                variant="outlined"
                error={Boolean(errors.name)}
                helperText={errors.name}
              />
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
              <TextField
                id="phone"
                label="เบอร์มือถือ"
                name="phone"
                value={inputWebPhoneData.phone}
                onChange={inputEvent}
                variant="outlined"
                error={Boolean(errors.phone)}
                helperText={errors.phone}
              />

              <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
                <TextField
                  select
                  id="webId"
                  name="webId"
                  value={inputWebPhoneData.webId}
                  label="เว็บ"
                  placeholder="เลือกเว็บ"
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    marginTop: '10px',
                    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'red',
                    },
                  }}
                  onChange={inputEvent}
                  variant="outlined"
                  error={Boolean(errors.webId)}
                  helperText={errors.webId}
                >
                  {webList.map((web) => (
                    <MenuItem key={web.id} value={web.id}>
                      {web.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '50px' }}>
              <Button onClick={() => submitCreateWebPhone()} variant="contained">
                บันทึก
              </Button>
            </Box>
          </Box>
        </Modal>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={webPhoneList.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {webPhoneList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                    const { id, name, phone, web, isStatus } = row;
                    index = index + 1;
                    return (
                      <TableRow key={id}>
                        <TableCell align="left"> </TableCell>
                        <TableCell align="left">{index}</TableCell>
                        <TableCell width={250} component="th" scope="row">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="subtitle2" noWrap>
                              {name}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{phone}</TableCell>
                        <TableCell align="left">{web}</TableCell>

                        <TableCell align="center">
                          <Button
                            style={{ width: '120px' }}
                            onClick={() => handleDeletedWebPhone(row)}
                            color="error"
                            variant="contained"
                            startIcon={<Iconify icon="eva:trash-2-outline" />}
                          >
                            ลบเบอร์
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 15, 20, 30, 50]}
            component="div"
            count={webPhoneList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </>
  );
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  borderRadius: '1rem',
  borderColor: 'transparent',
};
