import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
// @mui
import { Box, List, ListItemText, Typography } from '@mui/material';
//
import { StyledNavItem, StyledNavItemIcon } from './styles';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} />
        ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const userData = JSON.parse(localStorage.getItem('userInfo'));
  const { title, path, icon, info, auth } = item;
  let decodeData = '';
  let role = null;
  if (userData) {
    decodeData = jwtDecode(userData.token);
    decodeData = decodeData.payload;
    role = decodeData.role.name;
  }
  const isAuth = auth.includes(role);
  if (isAuth) {
    return (
      <StyledNavItem
        component={RouterLink}
        to={path}
        sx={{
          '&.active': {
            color: 'text.primary',
            bgcolor: 'action.selected',
            fontWeight: 'fontWeightBold',
          },
          display: 'flex',
        }}
      >
        <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

        <ListItemText disableTypography primary={title} />

        {info && info}
      </StyledNavItem>
    );
  } else {
    return null;
  }
}
