const ip = {
  localhost: 'http://localhost:3001',
  production: 'https://commission-api.ak47.services',
};
const apiIp = process.env.NODE_ENV === 'production' ? ip.production : ip.localhost;

const api = {
  apiUrl: `${apiIp}`,
  // login
  login: `${apiIp}/api/v1/users/login`,

  // user
  createUser: `${apiIp}/api/v1/users/create`,
  createUserPhone: `${apiIp}/api/v1/users/create-user-phone`,
  updateUser: `${apiIp}/api/v1/users/update`,
  getUserList: `${apiIp}/api/v1/users/list`,
  getRoleList: `${apiIp}/api/v1/users/role-list`,
  statusUser: `${apiIp}/api/v1/users/update-status`,
  deleteUserPhone: `${apiIp}/api/v1/users/delete-phone`,
  deleteUser: `${apiIp}/api/v1/users/delete`,
  updateRecentLogin: `${apiIp}/api/v1/users/update-recent-login`,

  // user - win loss
  createWebinclude: `${apiIp}/api/v1/webinclude/create`,
  getWebWinLoss: `${apiIp}/api/v1/webinclude/detail`,
  getWebList: `${apiIp}/api/v1/webinclude/list`,

  //sms
  getSmsList: `${apiIp}/api/v1/sms/list`,
  getSmsLastList: `${apiIp}/api/v1/sms/last-list`,
  updateSms: `${apiIp}/api/v1/sms/update-status`,
  getSmsWebPhoneList: `${apiIp}/api/v1/smsweb/list`,
  getSmsWebList: `${apiIp}/api/v1/smsweb/web-list`,
  createWebPhone: `${apiIp}/api/v1/smsweb/create-phone`,
  deleteWebPhone: `${apiIp}/api/v1/smsweb/delete-phone`,
};

export default api;
