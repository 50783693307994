import jwtDecode from "jwt-decode";
// ----------------------------------------------------------------------

export function checkRoleAdminOnly(token) {
  const decodeData = jwtDecode(token);
  if(decodeData.payload.role.name === "Administrator" || decodeData.payload.role.name === "Developer"){
    return true;
  }
  return false;
}
