import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import Swal from 'sweetalert2';
import { fDate } from '../utils/formatTime';
import dayjs, { Dayjs } from 'dayjs';
import { format, getTime } from 'date-fns';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Modal,
  TextField,
  Box,
  Alert,
  AlertTitle,
  Snackbar,
  Switch,
} from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// api
import api from '../config/services';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  // { id: 'none' },
  { id: 'no', label: 'ลำดับ', alignRight: false },
  { id: 'sender', label: 'ชื่อบัญชี', alignRight: false },
  { id: 'phone', label: 'จาก', alignRight: false },
  { id: 'message', label: 'ข้อความ', alignRight: false },
  { id: 'sendDate', label: 'เวลาที่ส่ง', alignRight: false },
  { id: 'action', label: 'จัดการ' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function SMSPage() {
  const userData = JSON.parse(localStorage.getItem('userInfo'));
  const [isLoading, setIsLoading] = useState(true);
  const [defaultData, setDefaultData] = useState([]);
  const [searchData, setSearchData] = useState({
    search: '',
    from: dayjs(),
    to: dayjs(),
  });

  useEffect(() => {
    getSMS();
  }, []);

  // get data

  const getSMS = async () => {
    const formatTimeFrom = formatDate(searchData.from);
    const formatTimeTo = formatDate(searchData.to);

    let searchText =
      searchData.search === ''
        ? `?from=${formatTimeFrom}&to=${formatTimeTo}`
        : `?search=${searchData.search}&from=${formatTimeFrom}&to=${formatTimeTo}`;

    const url = `${api.getSmsList}${searchText}`;
    await axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      })
      .then((res) => {
        const { data } = res;
        if (data.type === 'success') {
          setDefaultData(data.data);
        }
      })
      .catch((err) => {
        if (err.response.data.error.status === 401) {
          Swal.fire({
            title: 'Error!',
            text: err.response.data.error.errorType,
            icon: 'error',
            confirmButtonText: 'ตกลง',
          }).then((action) => {
            localStorage.clear();
            window.location.reload();
          });
        }
      });
    setIsLoading(false);
  };
  // get data

  const handleChangeStatus = (isStatus, id) => {
    updateStatus(isStatus, id);
  };

  const updateStatus = async (isStatus, id) => {
    const url = `${api.updateSms}/${id}`;
    const checked = !isStatus;
    await axios
      .put(
        url,
        { checked: `${checked.toString()}` },
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        }
      )
      .then((res) => {
        const { data } = res;
        if (data.type === 'success') {
          getSMS();
          Swal.fire({
            title: 'Success',
            text: 'ปรับสถานะเช็คข้อความสำเร็จ',
            icon: 'success',
            confirmButtonText: 'ตกลง',
          });
        } else {
          Swal.fire({
            title: 'Error!',
            text: 'พบข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ',
            icon: 'error',
            confirmButtonText: 'ตกลง',
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          title: 'Error!',
          text: 'พบข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ',
          icon: 'error',
          confirmButtonText: 'ตกลง',
        });
      });
  };

  const inputEvent = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setSearchData((lastValue) => {
      return {
        ...lastValue,
        [name]: value,
      };
    });
  };

  const formatDate = (value) => {
    return format(value.$d, 'yyyy-MM-dd');
  };

  const handleDateChange = (name, value) => {
    setSearchData((lastValue) => {
      return {
        ...lastValue,
        [name]: value,
      };
    });
  };

  const handleSearch = () => {
    getSMS();
  };

  const handleReport = () => {
    Swal.fire({
      html: 'กำลังประมวลผล',
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    let fileName = `รายงาน SMS_${format(new Date(), 'yyyy-MM-dd_HH-mm')}`;
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const wscols = [{ wch: 5 }, { wch: 25 }, { wch: 10 }, { wch: 255 }, { wch: 20 }, { wch: 10 }];
    let statementlist = [];
    const params = {
      page: 1,
      row: 1000000,
      ...(searchData.from ? { from: searchData.from } : {}),
      ...(searchData.to ? { to: searchData.to } : {}),
      ...(searchData.search ? { search: searchData.search } : {}),
      status: 'success',
    };
    defaultData.forEach((item, index) => {
      let eventTimeFormat =
        item.sender === '15991111'
          ? dayjs(item.eventTime).add(2, 'hour').format('YYYY-MM-DD HH:mm:ss')
          : dayjs(item.eventTime).format('YYYY-MM-DD HH:mm:ss');

      statementlist.push({
        no: index + 1,
        name: item.name,
        from: item.sender,
        message: item.message,
        eventTime: eventTimeFormat,
        checked: item.checked == 0 ? 'false' : 'true',
      });
    });

    let ws = XLSX.utils.json_to_sheet(statementlist);
    ws['!cols'] = wscols;
    const wb = { Sheets: { SMSReport: ws }, SheetNames: ['SMSReport'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);

    Swal.close();
  };

  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deleteId, setDeleteId] = useState('');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - defaultData.length) : 0;

  return (
    <>
      <Helmet>
        <title> ระบบจัดการ SMS | AK service </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            ระบบจัดการ SMS
          </Typography>
        </Stack>

        <Stack direction="column" alignItems="start" justifyContent="space-between" mb={5} columnGap={4} rowGap={2}>
          <Stack direction="column" alignItems="start" gap={1}>
            <Stack direction="row" alignItems="center" columnGap={1}>
              <Typography>ชื่อบัญชี / เบอร์มือถือ</Typography>
              <TextField
                id="search"
                label=""
                name="search"
                placeholder=""
                value={searchData.search}
                onChange={inputEvent}
                variant="outlined"
                size="small"
              />
            </Stack>

            <Stack direction="row" alignItems="center" columnGap={1}>
              <Typography>เวลาที่ส่ง จาก</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']} sx={{ p: 0, m: 0 }}>
                  <DatePicker
                    views={['day', 'month', 'year']}
                    value={searchData.from}
                    format="DD-MM-YYYY"
                    onChange={(newValue) => handleDateChange('from', newValue)}
                    slotProps={{ textField: { size: 'small' } }}
                  />
                </DemoContainer>
              </LocalizationProvider>
              <Typography>ถึง</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']} sx={{ p: 0, m: 0 }}>
                  <DatePicker
                    views={['day', 'month', 'year']}
                    value={searchData.to}
                    format="DD-MM-YYYY"
                    onChange={(newValue) => handleDateChange('to', newValue)}
                    slotProps={{ textField: { size: 'small' } }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Stack>
          </Stack>

          <Stack direction="row" alignItems="center" columnGap={2}>
            <Button onClick={() => handleSearch()} style={{ width: '120px' }} color="primary" variant="contained">
              ค้นหา
            </Button>
            <Button onClick={() => handleReport()} style={{ width: '120px' }} color="primary" variant="outlined">
              ออกรายงาน
            </Button>
          </Stack>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={defaultData.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                />
                {isLoading ? (
                  <TableBody>
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={12}>
                        <Box style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          Loading
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {defaultData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                      const { id, sender, from, name, message, eventTime, checked } = row;
                      index = index + 1;

                      let eventTimeFormat =
                        sender === '15991111'
                          ? dayjs(eventTime).add(2, 'hour').format('YYYY-MM-DD HH:mm:ss')
                          : dayjs(eventTime).format('YYYY-MM-DD HH:mm:ss');
                      return (
                        <TableRow key={id}>
                          {/* <TableCell align="left"></TableCell> */}
                          <TableCell align="center">{index}</TableCell>
                          <TableCell align="left" sx={{ width: '20%' }}>
                            <Typography variant="subtitle2" noWrap>
                              {name}
                            </Typography>
                          </TableCell>
                          <TableCell align="left" sx={{ width: '10%' }}>
                            {sender}
                          </TableCell>
                          <TableCell align="left" sx={{ width: '40%' }}>
                            {message}
                          </TableCell>
                          <TableCell align="left" sx={{ width: '20%' }}>
                            {eventTimeFormat}
                          </TableCell>
                          <TableCell sx={{ color: checked ? 'green' : 'red' }} align="left">
                            {checked ? (
                              <Button style={{ width: '120px' }} color="success" variant="contained" disabled>
                                เช็คแล้ว
                              </Button>
                            ) : (
                              <Button
                                style={{ width: '120px' }}
                                onClick={() => handleChangeStatus(checked, id)}
                                color="warning"
                                variant="contained"
                              >
                                เช็ค
                              </Button>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}

                    {!isLoading && defaultData.length === 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={12}>
                          <Box
                            style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                          >
                            No data
                          </Box>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100, 150, 300, 500]}
            component="div"
            count={defaultData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </>
  );
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  borderRadius: '1rem',
  borderColor: 'transparent',
};
